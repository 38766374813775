<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" fullscreen>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="primary"
                        dark
                        class="ml-auto ma-3"
                        @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        color="primary" dark class="ma-3 ml-0" v-on="on">
                      File Fresh Case
                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="primary"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>File Fresh Case</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card>
                    <v-card-text>
                      <v-form
                          ref="form"
                          v-model="validationRules.valid"
                          lazy-validation
                      >
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field @keydown.space="preventLeadingSpace"
                                          v-model="editedItem.cause_claimant"
                                          :counter="200"
                                          maxLength="200"
                                          :rules="validationRules.titleRules"
                                          label="Cause Title Claimant/s*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field @keydown.space="preventLeadingSpace"
                                          v-model="editedItem.cause_respondent"
                                          :counter="200"
                                          maxLength="200"
                                          :rules="validationRules.titleRules"
                                          label="Cause Title Respondent/s*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                                clearable
                                outlined
                                dense
                                :items="caseCategoryMed"
                                v-model="editedItem.case_category"
                                item-text="title"
                                item-value="value"
                                label="Case Category *"
                                :rules="validationRules.selectRules"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                                clearable
                                outlined
                                dense
                                :items="disputeValues"
                                v-model="editedItem.dispute_value"
                                item-text="title"
                                item-value="value"
                                label="Dispute Value *"
                                :rules="validationRules.selectRules"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-card flat>
                              <v-card-text>
                                <v-row>
                                  <CaseClaimants
                                      :caseClaimantsOld="editedItem.case_claimants ? editedItem.case_claimants : editedItem.case_claimants === []"/>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-card flat>
                              <v-card-text>
                                <v-row>
                                  <CaseRespondents
                                      :caseRespondentsOld="editedItem.case_respondents ? editedItem.case_respondents : editedItem.case_respondents === []"/>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>

                          <v-col cols="12" sm="12" class="mb-10 pb-10">
                            <label>Case Brief</label>
                            <quill-editor
                                style="height: 300px"
                                v-model="editedItem.brief"
                            />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-text-field @keydown.space="preventLeadingSpace"
                                          v-model="editedItem.document_name"
                                          :counter="200"
                                          maxLength="200"
                                          :rules="validationRules.titleRules"
                                          label="Document Name*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <template>
                              <v-file-input
                                  accept="application/pdf"
                                  clearable
                                  label="Upload Document"
                                  outlined
                                  @change="uploadFile($event, 'document_file')"
                                  :rules="reqFileRules"
                                  dense
                                  show-size
                              ></v-file-input>
                            </template>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-textarea @keydown.space="preventLeadingSpace"
                                        v-model="editedItem.document_description"
                                        :counter="500"
                                        maxLength="500"
                                        label="Document Description*"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDialog"
                      >Cancel
                      </v-btn
                      >
                      <v-btn
                          :disabled="!validationRules.valid"
                          color="blue darken-1"
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                    <v-col cols="12">
                      <div
                          v-if="errors != undefined && errors.length >= 0"
                          role="alert"
                          v-bind:class="{ show: errors.length }"
                          class="alert fade alert-danger"
                      >
                        <div
                            class="alert-text"
                            v-for="(error, i) in errors"
                            :key="i"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </v-col>
                  </v-card>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <div class="row">
                <div class="col-md-12">
                  <v-card-title>
                    Mediation Cases ({{ totalRecords }})
                    <v-spacer></v-spacer>
                    <v-text-field
                        @keydown.enter="searchQueryData"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-card-title>
                </div>
              </div>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:item.title="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.title"></p>
                  </div>
                </template>
                <template v-slot:item.status="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.status == 0" v-html="'Pending'"></p>
                  </div>
                </template>
                <template v-slot:item.cause_claimant="{ item }">
                  <div class="text-truncate">
                    <p>{{item.cause_claimant}} vs {{item.cause_respondent}}</p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn
                        color="primary"
                        @click="showEditDialog(item)"
                        dark
                        class="mr-2"
                        small
                    >
                      <v-icon dark small>fas fa-eye</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  CASES_LIST,
  CREATE_CASE,
  UPDATE_CASE,
  CLEAR_CASE_ERRORS,
  GET_CASE_DETAILS, DELETE_CASE
} from "@/core/services/store/cases.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import CaseClaimants from "@/view/pages/realstate/cases/CaseClaimants";
import CaseRespondents from "@/view/pages/realstate/cases/CaseRespondents";
import {UPLOAD_USER_FILE} from "@/core/services/store/auth.module";

export default {
  name: "mediation-cases",
  components: {
    CaseRespondents,
    CaseClaimants,
  },
  data() {
    return {
      headers: [
        {
          text: "Case Title",
          value: "cause_claimant",
          name: "cause_claimant",
          filterable: true,
          sortable: true
        },
        {
          text: "Case no.",
          value: "id",
          name: "id",
          filterable: false,
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          name: "status",
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   name: "actions",
        //   filterable: false,
        //   sortable: false
        // }
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        linkRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 500) || "Must be less than or equal to 500 characters"
        ],
        oLRules: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 1000) return true;
            return 'Must be in between 0 and 1000';
          },
        ],
        metaTitleRules: [
          v => (v || '').length <= 255 || 'Must be less than or equal to 255 characters'
        ],
        metaDesRules: [
          v => (v || '').length <= 500 || 'Must be less than or equal to 500 characters'
        ],
        selectRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select an option';
            else return true;
          }
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select at least one option";
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      editedItem: {},
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_CASE_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.brief = '';
        }, 500)
      }
      if (!val) this.editedItem = {};
      !val && this.$refs.form.resetValidation();
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Content Management", route: "cases"},
      {title: "Cases"}
    ]);
    if (this.$route.query.hasOwnProperty("addModule")) {
      this.dialog = true;
    }
  },
  methods: {
    refreshData() {
      this.params.sortBy = [];
      this.params.query = "";
      this.getByTypeValue = 'all';
      this.params.sortDesc = "desc";
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage;
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1);
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 3 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        query += "&service=" + 'mediation';
        this.$store
            .dispatch(CASES_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_CASE_DETAILS, item.id).then(data => {
        this.editedItem = data.records || {};
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_CASE;
        let id = item.id;
        this.$set(item, "service", "mediation")
        this.$set(item, "caseClaimants", this.$store.state.cases.caseClaimants);
        this.$set(item, "caseRespondents", this.$store.state.cases.caseRespondents);
        let data = {
          data: item
        };
        if (id) {
          method = UPDATE_CASE;
        }
        this.$store.dispatch(CLEAR_CASE_ERRORS);
        this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete? All relational data will be lost!",
        clr: "red",
        callback: function () {
          this.$store
              .dispatch(DELETE_CASE, {
                'id': item.id,
              })
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
    uploadFile(file, key) {
      if (file) {
        this.$store.dispatch(CLEAR_CASE_ERRORS);
        let formData = new FormData()
        formData.append("file", file, file.name)
        this.$store.dispatch(UPLOAD_USER_FILE, {data: formData}).then((data) => {
          this.editedItem[key] = data.records
        })
      }
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.cases.errors
    })
  }
};
</script>